import React, { createContext, useContext, useState, useRef } from "react";
import dologin from "../services/login";
import dologinKeyclaok from "../services/login_keycloak";
import useUserStore from "../store/useUserStore";
import useUserRolesStore from "../store/useUserRolesStore";
import { jwtDecode } from "jwt-decode";
import { cleanKeycloakCookies } from "../utils/cookies";

import { Toast } from "primereact/toast";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const toast = useRef(null);
  const { user, setUser } = useUserStore();
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const isLogged = user;
    return !!isLogged.user_id;
  });

  const login = async (email, password, token) => {
    return new Promise(async (resolve) => {
      try {
        const { setUserRoles } = useUserRolesStore.getState();

        const payload = { email, password };
        let response;
        
        if (password) {
          localStorage.setItem('isKcAda', 'false');
          response = await dologin(payload);
        } else if (token) {
          localStorage.setItem('isKcAda', 'true');
          response = await dologinKeyclaok(email, token);
        } else {
          throw new Error('Password or token required for authentication');
        }

        if (response) {
          if (password) {
            setUser(response.data);
          } else {
            let dataUser = response.data;
            dataUser.token = token;
            dataUser.user_id = dataUser.id;
            setUser(dataUser);
          }

          if (token) {
            try {
              const decoded = jwtDecode(token);
              const userRoles = decoded.roles || decoded.realm_access?.roles || [];
              const hasAdaPlus = userRoles.includes("ada-plus");
              setUserRoles([{ ada_plus: hasAdaPlus }]);
            } catch (e) {
              setUserRoles([{ ada_plus: false }]);
            }
          }

          setIsAuthenticated(true);
          resolve(true);
        } else {
          throw new Error('Invalid login response');
        }
      } catch (error) {
        showError(error);
        setIsAuthenticated(false);
        resolve(false);
      }
    });
  };

  const logout = () => {
    sessionStorage.removeItem("@ada-user");
    sessionStorage.removeItem("@ada-company");
    localStorage.removeItem("isKcAda");
    localStorage.removeItem("last_client");
    
    cleanKeycloakCookies();
    setIsAuthenticated(false);
    
    if (window.keycloak && window.keycloak.authenticated) {
      try {
        window.keycloak.logout({ redirectUri: window.location.origin });
      } catch (e) {
        window.location.href = '/';
      }
    } else {
      window.location.href = '/';
    }
  };

  const showError = (error) => {
    let msg = "Authentication error";
    
    if (error?.response?.data?.message) {
      msg = error.response.data.message;
    } else if (error?.message) {
      msg = error.message;
    }
    
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: msg,
      life: 3000,
    });
  };

  return (
    <>
      <Toast ref={toast} />
      <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
        {children}
      </AuthContext.Provider>
    </>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
