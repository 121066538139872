import React, { useEffect, useState } from 'react';
import { keycloak } from '../../keycloak';
import { useAuth } from '../../hooks/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import {
    LoadingOverlay,
    LoadingSpinnerWrapper,
    LoadingMessageWrapper,
    LoadingMessage 
} from './styles'
import { cleanKeycloakCookies } from '../../utils/cookies';

const Intercept = () => {
    const { login } = useAuth();
    const { clientId } = useParams();
    const navigate = useNavigate();
    const [password, setPassword] = useState(null);
    const [loading, setLoading] = useState(false);
    const [flag, setFlag] = useState(false);

    useEffect(() => {
        localStorage.setItem('isKcAda', 'true');
        localStorage.setItem('last_client', clientId);
        
        cleanKeycloakCookies();
        
        setTimeout(() => {
            try {
                if (!keycloak) {
                    window.location.reload();
                    return;
                }
                
                if (!keycloak.authenticated) {
                    const queryString = window.location.search;
                    const params = new URLSearchParams(queryString);
                    const module = params.get("module");

                    try {
                        const redirectUri = window.location.origin + '/intercept/' + clientId + 
                                           (module ? `?module=${module}` : '');
                        
                        keycloak.login({ 
                            redirectUri: redirectUri
                        });
                    } catch (e) {
                        navigate('/');
                    }
                } else {
                    setFlag(true);
                }
            } catch (error) {
                navigate('/');
            }
        }, 1000);
        
    }, [clientId, navigate]);

    useEffect(() => {
        if (flag && keycloak) {
            if (keycloak.authenticated) {
                localStorage.setItem('isKcAda', 'true');
                localStorage.setItem('last_client', clientId);
                handleLogin();
            } else {
                navigate('/');
            }
        }
    }, [flag, clientId, navigate])

    const handleLogin = async () => {
        try {
            setLoading(true);

            const queryString = window.location.search;
            const params = new URLSearchParams(queryString);
            const module = params.get("module");

            if (!keycloak || !keycloak.token || !keycloak.tokenParsed) {
                throw new Error('Keycloak is not properly initialized');
            }

            const response = await login(keycloak.tokenParsed.email, password, keycloak.token);

            const decoded = jwtDecode(keycloak.token);
            const userRoles = decoded.roles || decoded.realm_access?.roles || [];
            const hasAdaPlus = userRoles.includes("ada-plus");

            if (response) {
                if (!module) {
                    if (hasAdaPlus) {
                        navigate(`/home-plus`);
                    } else {
                        navigate(`/home`);
                    } 
                } else {
                    navigate(`/${module}`);
                }
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            navigate('/');
        }
    };

    return (
        <div>
            {loading && <LoadingOverlay>
                <LoadingSpinnerWrapper />
                <LoadingMessageWrapper>
                </LoadingMessageWrapper>
            </LoadingOverlay>
            }
        </div>
    )
};

export default Intercept;