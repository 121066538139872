import React, { useEffect, useState, useRef, useCallback } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import Header from '../../components/Header';
import useUserStore from '../../store/useUserStore';
import useClientSelectedStore from '../../store/map/useClientSelectedStore';
import useDateSelectedStore from '../../store/map/useDateSelectedStore';
import _debounce from 'lodash/debounce';
import useDropdownStore from '../../store/useDropdownStore';
import useCompanySectorStore from '../../store/useCompanySectorStore';
import { allSectors, map_pins_data_heat_map } from '../../services/dashboard';
import MapHeatMapComponent from '../../components/Maps/HeatMap';
import { useIntl, FormattedMessage } from 'react-intl';
import { Container, SidebarContainer, SidebarToggleButton, Content, MapContainer, ChartContainer, Controls, CustomSidebar, ApplyButton, StyledCalendar, StyledButtonNavigation, StyledSlider } from './styles';
import PressureChart from '../../components/Charts/PressureHeatMap';
import { useSliderHeatMapStore } from '../../store/useSliderHeatMapStore';
import { usePressureStore } from '../../store/heatmap/usePressureStore';
import { calculateDateFromSlider, parseDateRange } from '../../utils/Utils';
import './styles.css';
import '../../locales/calendarLocales';

const InstallPointsHeatMap = () => {
  const intl = useIntl();
  const toast = useRef(null);
  const { user } = useUserStore();
  const { clientSelected } = useClientSelectedStore();
  const { dateSelected } = useDateSelectedStore();
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [selectedSectors, setSelectedSectors] = useState([]);
  /* const [dateRange, setDateRange] = useState(() => {
    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);
    return [thirtyDaysAgo, today];
  }); */
  const [dateRange, setDateRange] = useState(() => {
    const today = new Date();
    today.setHours(23, 59, 59, 999); // Fim do dia local

    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);
    thirtyDaysAgo.setHours(0, 0, 0, 0); // Início do dia local

    return [thirtyDaysAgo, today];
  });
  const [periodicity, setPeriodicity] = useState('day');
  const [referenceValue, setReferenceValue] = useState('pressAvg');
  const [sliderRange, setSliderRange] = useState([0, 0]);
  const { disableDropdown, enableDropdown } = useDropdownStore();
  const [changeSize, setChangeSize] = useState(false);
  const [indexSelected, setIndexSelected] = useState(2);
  const [items, setItems] = useState([]);
  const [filter, setFilter] = useState('');
  const [listSectors, setListSectors] = useState([]);
  const [devices, setDevices] = useState([]);
  const [devicesFiltered, setDevicesFiltered] = useState([]);
  const [devicesFilteredDraw, setDevicesFilteredDraw] = useState([]);
  const [flags, setFlags] = useState({
    map: false,
    cards: false,
  });
  const { setClientIdSelected, setSectorIdSelected, setStartDateSelected, setEndDateSelected } = useCompanySectorStore();
  const { setSliderHeatMap } = useSliderHeatMapStore();
  const [loading, setLoading] = useState(false);
  const [mapKey, setMapKey] = useState(0);
  const [maxSliderSteps, setMaxSliderSteps] = useState(0);
  const [currentStartDate, setCurrentStartDate] = useState(null);
  const [currentEndDate, setCurrentEndDate] = useState(null);
  const [itemSelected, setItemSelected] = useState(null);
  const [mapCenter, setMapCenter] = useState(null);
  const { minPressure, maxPressure, setMinPressure, setMaxPressure } = usePressureStore();
  const [sliderStartDate, setSliderStartDate] = useState(null);
  const [sliderEndDate, setSliderEndDate] = useState(null);
  const [hasPolygon, setHasPolygon] = useState(false);
  const [linePointSelected, setLinePointSelected] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const getStep = () => {
    const MS_PER_DAY = 24 * 60 * 60 * 1000;

    switch (periodicity) {
      case 'day':
        return MS_PER_DAY;
      case 'week':
        return 7 * MS_PER_DAY;
      case 'fifteen_days':
        return 15 * MS_PER_DAY;
      case 'thirty_days':
        return 30 * MS_PER_DAY;
      default:
        return MS_PER_DAY;
    }
  };

  const calculateMaxSteps = useCallback(() => {
    if (!dateRange || !dateRange[0] || !dateRange[1]) {
      return 0;
    }

    const startDate = new Date(dateRange[0]);
    const endDate = new Date(dateRange[1]);

    if (periodicity === 'day') {
      const startDay = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
      const endDay = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
      const diffTime = endDay - startDay;
      const diffDays = Math.ceil(diffTime / (24 * 60 * 60 * 1000)) + 1;
      return Math.max(diffDays, 1);
    }

    const totalDuration = endDate.getTime() - startDate.getTime();
    const stepDuration = getStep();

    if (totalDuration < stepDuration) {
      return 1;
    }

    const fullSteps = Math.floor(totalDuration / stepDuration);
    const remaining = totalDuration % stepDuration;
    const steps = remaining > stepDuration * 0.1 ? fullSteps + 1 : fullSteps;
    return Math.max(steps, 1);
  }, [dateRange, periodicity]);

  useEffect(() => {
    const steps = calculateMaxSteps();
    setMaxSliderSteps(steps);
  }, [periodicity, dateRange, calculateMaxSteps]);

  useEffect(() => {
    handleStop();
  }, [periodicity]);

  const handleFilterMapDraw = useCallback((visibleDevices) => {
    try {
      if (visibleDevices && typeof visibleDevices === 'object' && visibleDevices.polygonCleared) {
        setDevicesFilteredDraw([]);
        setHasPolygon(false);
        return;
      }

      if (!Array.isArray(visibleDevices) || visibleDevices.length === 0) {
        setDevicesFilteredDraw([]);
        setHasPolygon(false);
        return;
      }

      if (!Array.isArray(devices) || devices.length === 0) {
        setDevicesFilteredDraw([]);
        setHasPolygon(false);
        return;
      }

      setHasPolygon(true);

      const visibleDeviceIds = visibleDevices
        .filter(d => d && (d.id || d.deviceId))
        .map(d => d.id || d.deviceId);

      if (visibleDeviceIds.length === 0) {
        setDevicesFilteredDraw([]);
        return;
      }

      const allFilteredDevices = devices.filter(device => {
        if (!device) return false;

        const isVisible = visibleDeviceIds.includes(device.id || device.deviceId);

        const pressureValue = device[referenceValue];
        const meetsPressCriteria = pressureValue >= minPressure && pressureValue <= maxPressure;

        /* let isInTimeRange = true;
        if (device.datetime && dateRange && dateRange[0] && dateRange[1]) {
          try {
            const deviceDate = new Date(device.datetime).getTime();
            isInTimeRange = deviceDate >= dateRange[0].getTime() &&
              deviceDate <= dateRange[1].getTime();
          } catch (error) {
            console.error("Error processing device date:", error);
            isInTimeRange = false;
          }
        } */
        let isInTimeRange = true;
        if (device.datetime && dateRange && dateRange[0] && dateRange[1]) {
          try {
            const deviceDate = new Date(device.datetime);
            // Remove a informação de UTC/Z, forçando interpretação como local
            if (device.datetime.endsWith('Z')) {
              deviceDate.setMinutes(deviceDate.getMinutes() - deviceDate.getTimezoneOffset());
            }

            isInTimeRange = deviceDate >= dateRange[0] &&
              deviceDate <= dateRange[1];
          } catch (error) {
            console.error("Error processing device date:", error);
            isInTimeRange = false;
          }
        }
        /*         if (!isInTimeRange) {
                  console.log('device Fora', device)
                }
        
                if (isInTimeRange) {
                  console.log('device Dentro', device)
                } */
        return isVisible && meetsPressCriteria && isInTimeRange;
      });

      setDevicesFilteredDraw(allFilteredDevices);
    } catch (error) {
      console.error("Error in handleFilterMapDraw:", error);
      setDevicesFilteredDraw([]);
      setHasPolygon(false);
    }
  }, [devices, dateRange, referenceValue, minPressure, maxPressure]);

  const updateInterval = useCallback((rangeValues) => {
    if (!devices || devices.length === 0 || !dateRange || !dateRange[0] || !dateRange[1]) {
      return;
    }

    const step = getStep();

    const startDate = new Date(dateRange[0].getTime() + (rangeValues[0] * step));

    let endDate;
    if (periodicity === 'day') {
      const daysToAdd = rangeValues[1] - rangeValues[0];
      endDate = new Date(startDate.getTime() + (daysToAdd * 24 * 60 * 60 * 1000));
    } else {
      switch (periodicity) {
        case 'week':
          endDate = new Date(startDate.getTime() + (7 * 24 * 60 * 60 * 1000));
          break;
        case 'fifteen_days':
          endDate = new Date(startDate.getTime() + (15 * 24 * 60 * 60 * 1000));
          break;
        case 'thirty_days':
          endDate = new Date(startDate.getTime() + (30 * 24 * 60 * 60 * 1000));
          break;
        default:
          endDate = new Date(startDate.getTime() + (24 * 60 * 60 * 1000));
      }
    }

    const limitedEndDate = new Date(Math.min(endDate.getTime(), dateRange[1].getTime()));

    setSliderStartDate(startDate);
    setSliderEndDate(limitedEndDate);

    const timeFilteredDevices = devices.filter((device) => {
      const deviceDate = new Date(device.datetime).getTime();
      return deviceDate >= startDate.getTime() && deviceDate <= limitedEndDate.getTime();
    });

    const pressureFilteredDevices = timeFilteredDevices.filter((device) => {
      const pressureValue = device[referenceValue];
      return pressureValue >= minPressure && pressureValue <= maxPressure;
    });

    setDevicesFiltered(pressureFilteredDevices);

    setCurrentStartDate(startDate);
    setCurrentEndDate(limitedEndDate);
  }, [devices, dateRange, minPressure, maxPressure, referenceValue, periodicity]);

  const calculateSecondPoint = useCallback((firstPoint) => {
    const totalDaysInRange = (dateRange[1].getTime() - dateRange[0].getTime()) / (24 * 60 * 60 * 1000);

    const stepsPerDay = maxSliderSteps / totalDaysInRange;

    let daysToAdd;
    switch (periodicity) {
      case 'day':
        daysToAdd = 1;
        break;
      case 'week':
        daysToAdd = 7;
        break;
      case 'fifteen_days':
        daysToAdd = 15;
        break;
      case 'thirty_days':
        daysToAdd = 30;
        break;
      default:
        daysToAdd = 1;
    }

    const secondPoint = Math.min(firstPoint + (daysToAdd * stepsPerDay), maxSliderSteps);

    return Math.round(secondPoint);
  }, [dateRange, maxSliderSteps, periodicity]);

  const handleSliderChange = (value) => {
    let newRange;

    if (periodicity === 'day') {
      newRange = [value[0], value[1]];

      if (value[1] < value[0] + 1) {
        newRange[1] = value[0] + 1;
      }
    } else {
      const firstPointMoved = value[0] !== sliderRange[0];

      if (firstPointMoved) {
        const secondPoint = calculateSecondPoint(value[0]);
        newRange = [value[0], secondPoint];
      } else {
        newRange = [sliderRange[0], Math.max(value[1], calculateSecondPoint(sliderRange[0]))];
      }
    }

    if (newRange[1] > maxSliderSteps) {
      newRange[1] = maxSliderSteps;
    }

    setSliderRange(newRange);
    setSliderHeatMap(newRange[0]);

    updateInterval(newRange);
  };

  const handleSliderNavigation = (direction) => {
    if (direction === 'left' && sliderRange[0] > 0) {
      const newFirstPoint = Math.max(0, sliderRange[0] - 1);
      let newRange;

      if (periodicity === 'day') {
        const distance = sliderRange[1] - sliderRange[0];
        const newSecondPoint = Math.min(newFirstPoint + distance, maxSliderSteps);
        newRange = [newFirstPoint, newSecondPoint];
      } else {
        const newSecondPoint = calculateSecondPoint(newFirstPoint);
        newRange = [newFirstPoint, newSecondPoint];
      }

      setSliderRange(newRange);
      setSliderHeatMap(newRange[0]);
      updateInterval(newRange);
    } else if (direction === 'right' && sliderRange[0] < maxSliderSteps - 1) {
      const newFirstPoint = Math.min(maxSliderSteps - 1, sliderRange[0] + 1);
      let newRange;

      if (periodicity === 'day') {
        const distance = sliderRange[1] - sliderRange[0];
        const newSecondPoint = Math.min(newFirstPoint + distance, maxSliderSteps);
        newRange = [newFirstPoint, newSecondPoint];
      } else {
        const newSecondPoint = calculateSecondPoint(newFirstPoint);
        newRange = [newFirstPoint, newSecondPoint];
      }

      setSliderRange(newRange);
      setSliderHeatMap(newRange[0]);
      updateInterval(newRange);
    }
  };

  useEffect(() => {
    let intervalId;

    if (isPlaying) {
      intervalId = setInterval(() => {
        handleSliderNavigation('right');
      }, 1000); // Avança o slider a cada segundo
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isPlaying, handleSliderNavigation]);

  const handlePlayPause = () => {
    setIsPlaying((prev) => !prev);
  };


  const handleStop = () => {
    setIsPlaying(false); 
    setSliderRange([0, 1]); 
    setSliderHeatMap(0); 
    updateInterval([0, 1]); 
  };


  useEffect(() => {
    disableDropdown();
    debouncedGetSectors();
    return debouncedGetSectors.cancel;
  }, [user, clientSelected]);

  useEffect(() => {
    return () => { };
  }, [dateSelected, clientSelected, itemSelected]);

  useEffect(() => {
    if (filter.length > 0) {
      const result = listSectors.filter((setor) =>
        setor.sectorName.toLowerCase().includes(filter.toLowerCase())
      );
      setItems(result);
    } else {
      setItems(listSectors);
    }
  }, [filter, listSectors]);

  const debouncedGetSectors = _debounce(() => {
    if (user) {
      setIndexSelected(2);
      getSectors();
    }
  }, 500);

  const getSectors = async () => {
    try {
      const payload = {
        'clientId': user.type_user === '1' && clientSelected.id ? clientSelected.id : user.client_id,
      }
      const response = await allSectors(payload);
      let filteredData = response.data.filter(sector => !sector.isMaster);
      setItems(filteredData);
      setListSectors(filteredData);

      if (filteredData && filteredData.length === 0) {
        setItems([]);
        setListSectors([]);
        setDevices([]);
      }

    } catch (error) {
      if (error.response) {
        toast.current.show({
          severity: 'error',
          summary: intl.formatMessage({ id: "error_summary" }),
          detail: intl.formatMessage({ id: "error_loading_sectors_server" }),
          life: 10000
        });
      } else if (error.request) {
        toast.current.show({
          severity: 'error',
          summary: intl.formatMessage({ id: "error_summary" }),
          detail: intl.formatMessage({ id: "error_loading_sectors_connectivity" }),
          life: 10000
        });
      } else {
        toast.current.show({
          severity: 'error',
          summary: intl.formatMessage({ id: "error_summary" }),
          detail: intl.formatMessage({ id: "error_loading_sectors_generic" }),
          life: 10000
        });
      }
    }
  }

  const dataChart = async (data) => {
    setLoading(true);
    setFlags(prevFlags => ({ ...prevFlags, cards: false, map: false }));
    setHasPolygon(false);

    try {
      setDevicesFiltered([]);
      setDevicesFilteredDraw([]);
      setDevices([]);

      setMapKey(prevKey => prevKey + 1);

      if (!dateRange || !dateRange[0] || !dateRange[1]) {
        setLoading(false);
        return;
      }

      const finalStartDate = `${dateRange[0].getFullYear()}-${(dateRange[0].getMonth() + 1).toString().padStart(2, '0')}-${dateRange[0].getDate().toString().padStart(2, '0')}`;
      const finalEndDate = `${dateRange[1].getFullYear()}-${(dateRange[1].getMonth() + 1).toString().padStart(2, '0')}-${dateRange[1].getDate().toString().padStart(2, '0')}`;

      setStartDateSelected(finalStartDate);
      setEndDateSelected(finalEndDate);

      if (!data || !data.clientId) {
        setLoading(false);
        return;
      }

      const payload = {
        dti: finalStartDate,
        dtf: finalEndDate,
        clientId: data.clientId,
      };

      if (data.sectorId) {
        payload.sectorId = data.sectorId;
      }

      const mapPinsDataHeatMapRes = await map_pins_data_heat_map(payload);

      if (mapPinsDataHeatMapRes && mapPinsDataHeatMapRes.data && Array.isArray(mapPinsDataHeatMapRes.data) && mapPinsDataHeatMapRes.data.length > 0) {
        await new Promise(resolve => setTimeout(resolve, 50));

        const devicesData = mapPinsDataHeatMapRes.data;

        const validDevicesData = devicesData.filter(device =>
          device &&
          device.datetime &&
          (device.lat !== undefined && device.long !== undefined) &&
          !isNaN(parseFloat(device.lat)) &&
          !isNaN(parseFloat(device.long))
        );

        setDevices(validDevicesData);

        if (validDevicesData.length > 0) {
          const sumLat = validDevicesData.reduce((sum, device) => sum + parseFloat(device.lat), 0);
          const sumLng = validDevicesData.reduce((sum, device) => sum + parseFloat(device.long), 0);

          const centerLat = sumLat / validDevicesData.length;
          const centerLng = sumLng / validDevicesData.length;

          setMapCenter({ lat: centerLat, lng: centerLng });

          const step = getStep();
          const startDate = new Date(dateRange[0].getTime());
          const endDate = new Date(startDate.getTime() + step);

          const timeFilteredDevices = validDevicesData.filter((device) => {
            try {
              const deviceDate = new Date(device.datetime).getTime();
              return deviceDate >= startDate.getTime() && deviceDate <= endDate.getTime();
            } catch (error) {
              console.error("Error filtering device by time:", error);
              return false;
            }
          });

          const pressureFilteredDevices = timeFilteredDevices.filter((device) => {
            try {
              const pressureValue = device[referenceValue];
              return pressureValue >= minPressure && pressureValue <= maxPressure;
            } catch (error) {
              console.error("Error filtering device by pressure:", error);
              return false;
            }
          });

          setDevicesFiltered(pressureFilteredDevices);
          setCurrentStartDate(startDate);
          setCurrentEndDate(endDate);

          setFlags(prevFlags => ({ ...prevFlags, cards: true, map: true }));
        } else {
          toast.current.show({
            severity: 'warn',
            summary: intl.formatMessage({ id: "warning_summary" }),
            detail: intl.formatMessage({ id: "title_heat_map_no_valid_devices" }),
            life: 5000
          });
        }
      } else {
        toast.current.show({
          severity: 'warn',
          summary: intl.formatMessage({ id: "warning_summary" }),
          detail: intl.formatMessage({ id: "title_heat_map_no_data_available" }),
          life: 5000
        });
      }

      enableDropdown();
    } catch (error) {
      console.error("Error in dataChart:", error);
      enableDropdown();

      if (error.response) {
        toast.current.show({
          severity: 'error',
          summary: intl.formatMessage({ id: "error_summary" }),
          detail: intl.formatMessage({ id: "error_loading_heat_map_server" }),
          life: 10000
        });
      } else if (error.request) {
        toast.current.show({
          severity: 'error',
          summary: intl.formatMessage({ id: "error_summary" }),
          detail: intl.formatMessage({ id: "error_loading_heat_map_connectivity" }),
          life: 10000
        });
      } else {
        toast.current.show({
          severity: 'error',
          summary: intl.formatMessage({ id: "error_summary" }),
          detail: intl.formatMessage({ id: "error_loading_heat_map_generic" }),
          life: 10000
        });
      }

      setFlags(prevFlags => ({ ...prevFlags, cards: false, map: false }));
    } finally {
      setLoading(false);
    }
  };

  const handleApplyFilter = () => {

    setLoading(true);
    setHasPolygon(false);
    setPeriodicity('day');
    setDevicesFilteredDraw([]);

    const firstPoint = 0;
    setTimeout(() => {
      let secondPoint;

      if (periodicity === 'day') {
        secondPoint = 1;
      } else {
        secondPoint = calculateSecondPoint(firstPoint);
      }

      setSliderRange([firstPoint, secondPoint]);
      setSliderHeatMap(firstPoint);
    }, 100);

    if (!selectedSectors || selectedSectors.length === 0) {
      toast.current.show({
        severity: 'warn',
        summary: intl.formatMessage({ id: "warning_summary" }),
        detail: intl.formatMessage({ id: "title_heat_map_select_sector_first" }),
        life: 5000
      });
      setLoading(false);
      return;
    }

    if (dateRange && dateRange[0] && dateRange[1]) {
      const startDate = new Date(dateRange[0]);
      const endDate = new Date(dateRange[1]);
      const diffTime = Math.abs(endDate - startDate);
      const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24;
      const MAX_DATE_RANGE_DAYS = 90;

      const diffDays = Math.ceil(diffTime / MILLISECONDS_PER_DAY);

      if (diffDays > MAX_DATE_RANGE_DAYS) {
        toast.current.show({
          severity: 'error',
          summary: intl.formatMessage({ id: "error_summary" }),
          detail: intl.formatMessage({ id: "error_date_range_too_large" }),
          life: 7000
        });
        setLoading(false);
        return;
      }
    }

    const clientId = user.type_user === '1' && clientSelected?.id ?
      clientSelected.id : user.client_id;

    if (!clientId) {
      setLoading(false);
      return;
    }

    const allSectorsSelected = selectedSectors.length > 1 ||
      (selectedSectors.length === 1 && selectedSectors[0].sectorId === "all");

    const payload = {
      clientId: clientId
    };

    if (!allSectorsSelected && selectedSectors.length === 1) {
      payload.sectorId = selectedSectors[0].sectorId;
    }

    setClientIdSelected(payload.clientId);
    setSectorIdSelected(allSectorsSelected ? null : payload.sectorId);
    dataChart(payload);
  };

  useEffect(() => {
    if (devices.length > 0 && dateRange && dateRange[0] && dateRange[1] && sliderRange) {
      const timeoutId = setTimeout(() => {
        updateInterval(sliderRange);
      }, 100);

      return () => clearTimeout(timeoutId);
    }
  }, [devices.length]);

  function getMaxMinPressure(devices, pressureType) {
    const validTypes = ['pressAvg', 'pressMax', 'pressMin'];
    if (!validTypes.includes(pressureType)) {
      throw new Error('Tipo de pressão inválido. Use "pressAvg", "pressMax" ou "pressMin".');
    }

    let maxPressure = -Infinity;
    let minPressure = Infinity;

    devices.forEach(device => {
      const pressureValue = device[pressureType];
      if (pressureValue > maxPressure) {
        maxPressure = pressureValue;
      }
      if (pressureValue < minPressure) {
        minPressure = pressureValue;
      }
    });

    return {
      max: maxPressure,
      min: minPressure
    };
  }

  return (
    <>
      <Toast ref={toast} />
      <Header />
      {loading && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 9999
        }}>
          <ProgressSpinner strokeWidth='4' className="p-progress-spinner-circle" style={{ width: '80px', height: '80px' }} />
        </div>
      )}
      <Container>
        <SidebarContainer $collapsed={sidebarCollapsed}>
          {!sidebarCollapsed ? (
            <CustomSidebar
              visible={!sidebarCollapsed}
              modal={false}
              dismissable={false}
              style={{ width: '21%', top: '60px' }}
            >
              <SidebarToggleButton
                icon={`pi pi-chevron-${sidebarCollapsed ? 'right' : 'left'}`}
                onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
              />
              <h3>
                <FormattedMessage id="title_heat_map_filter" />
              </h3>
              <div className="p-fluid d-flex flex-column gap-3">
                <div className="p-field">
                  <label htmlFor="setor">
                    <FormattedMessage id="title_heat_map_sector" />
                  </label>
                  <Dropdown
                    id="setor"
                    value={selectedSectors.length === 1 ? selectedSectors[0] :
                      selectedSectors.length > 1 ? { sectorId: "all", sectorName: intl.formatMessage({ id: "title_heat_map_all_sectors" }) } : null}
                    options={[
                      { sectorId: "all", sectorName: intl.formatMessage({ id: "title_heat_map_all_sectors" }) || "Todos los sectores" },
                      ...listSectors
                    ]}
                    onChange={(e) => {
                      if (e.value && e.value.sectorId === "all") {
                        setSelectedSectors(listSectors);
                      } else if (e.value) {
                        setSelectedSectors([e.value]);
                      } else {
                        setSelectedSectors([]);
                      }
                    }}
                    optionLabel="sectorName"
                    placeholder={intl.formatMessage({ id: "title_heat_map_select_sectors" })}
                    filterPlaceholder={intl.formatMessage({ id: 'msg_input_filter_sector' })}
                    filter
                  />
                </div>
                <div className="p-field">
                  <label htmlFor="maximo">
                    <FormattedMessage id="title_heat_map_max" />
                  </label>
                  <InputNumber id="maximo" value={maxPressure} onValueChange={(e) => setMaxPressure(e.value)} />
                </div>
                <div className="p-field">
                  <label htmlFor="minimo">
                    <FormattedMessage id="title_heat_map_min" />
                  </label>
                  <InputNumber id="minimo" value={minPressure} onValueChange={(e) => setMinPressure(e.value)} />
                </div>
                <div className="p-field">
                  <label htmlFor="periodo">
                    <FormattedMessage id="title_heat_map_date_period" />
                  </label>
                  <StyledCalendar
                    id="periodo"
                    value={dateRange}
                    onChange={(e) => setDateRange(e.value)}
                    selectionMode="range"
                    showIcon
                    dateFormat="dd/mm/yy"
                    style={{ width: '100%' }}
                    locale={intl?.locale}
                  />
                </div>
                <div className="p-field">
                  <label htmlFor="referenceValue">
                    <FormattedMessage id="title_heat_map_reference_value" />
                  </label>
                  <Dropdown
                    id="referenceValue"
                    value={referenceValue}
                    options={[
                      { label: intl.formatMessage({ id: "title_heat_map_reference_value_min_pressure" }), value: 'pressMin' },
                      { label: intl.formatMessage({ id: "title_heat_map_reference_value_avg_pressure" }), value: 'pressAvg' },
                      { label: intl.formatMessage({ id: "title_heat_map_reference_value_max_pressure" }), value: 'pressMax' },
                    ]}
                    onChange={(e) => setReferenceValue(e.value)}
                    optionLabel="label"
                    placeholder={intl.formatMessage({ id: "title_heat_map_select_reference_value" })}
                  />
                </div>
                <ApplyButton
                  label={intl.formatMessage({ id: "title_heat_map_btn_apply_filter" })}
                  onClick={handleApplyFilter}
                />
              </div>
            </CustomSidebar>
          ) : null}
          <SidebarToggleButton
            icon={`pi pi-chevron-${sidebarCollapsed ? 'right' : 'left'}`}
            onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
            style={{
              position: 'absolute',
              top: '10px',
              left: sidebarCollapsed ? '5px' : 'calc(21% - 20px)',
              transition: 'left 0.3s',
              display: sidebarCollapsed ? 'block' : 'none',
            }}
          />
        </SidebarContainer>
        <Content $collapsed={sidebarCollapsed}>
          <MapContainer
            $collapsed={sidebarCollapsed}
            style={{
              flexGrow: devicesFilteredDraw && Array.isArray(devicesFilteredDraw) && devicesFilteredDraw.length > 0 ? 0.65 : 1,
              height: devicesFilteredDraw && Array.isArray(devicesFilteredDraw) && devicesFilteredDraw.length > 0 ? '55vh' : 'calc(100vh - 150px)'
            }}
          >
            <MapHeatMapComponent
              key={mapKey}
              devices={devicesFiltered}
              onDevicesFiltered={handleFilterMapDraw}
              mapCenter={mapCenter}
              typeValue={referenceValue}
              maxMinValue={getMaxMinPressure(devices, referenceValue)}
              pointSelected={linePointSelected}
              onPlay={handlePlayPause}
              onStop={handleStop}
              isPlaying={isPlaying}
              onPeriodicity={(periodicity) => setPeriodicity(periodicity)}
            />
          </MapContainer>
          {
            devices && devices.length > 0 && (
              <Controls>
                <StyledButtonNavigation
                  icon="pi pi-chevron-left"
                  onClick={() => handleSliderNavigation('left')}
                  disabled={sliderRange[0] === 0}
                />
                <StyledSlider
                  value={sliderRange}
                  onChange={(e) => handleSliderChange(e.value)}
                  min={0}
                  max={maxSliderSteps}
                  range={true}
                  date={calculateDateFromSlider(dateRange, intl.locale, sliderRange, periodicity)}
                  disabled={true}
                />
                <StyledButtonNavigation
                  icon="pi pi-chevron-right"
                  onClick={() => handleSliderNavigation('right')}
                  disabled={sliderRange[0] >= maxSliderSteps}
                />
              </Controls>
            )
          }

          {hasPolygon ? (
            <ChartContainer
              $collapsed={sidebarCollapsed}
              style={{
                height: '35vh',
                minHeight: '280px',
                marginTop: '10px',
                border: '1px solid #ddd',
                borderRadius: '4px',
                padding: '10px',
                boxShadow: '0 2px 5px rgba(0,0,0,0.1)'
              }}
            >
              {devicesFilteredDraw && devicesFilteredDraw.length > 0 ? (
                <PressureChart
                  devices={devicesFilteredDraw}
                  expanded={true}
                  startDate={dateRange && dateRange[0] ? dateRange[0] : null}
                  endDate={dateRange && dateRange[1] ? dateRange[1] : null}
                  referenceValue={referenceValue}
                  onLineClick={setLinePointSelected}
                  verticalLineDate={parseDateRange(calculateDateFromSlider(dateRange, intl.locale, sliderRange, periodicity), intl.locale)}
                />
              ) : (
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  flexDirection: 'column'
                }}>
                  <i className="pi pi-info-circle" style={{ fontSize: '2rem', color: '#ccc', marginBottom: '10px' }}></i>
                  <p style={{ textAlign: 'center', color: '#666' }}>
                    {intl.formatMessage({ id: "title_heat_map_no_data_in_range" }) || "No hay datos disponibles en este rango de tiempo"}
                  </p>
                </div>
              )}
            </ChartContainer>
          ) : null}
        </Content>
      </Container>
    </>
  );
};

export default InstallPointsHeatMap;