import React, { useState } from 'react';
import { RadioButton } from 'primereact/radiobutton';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';

const SidebarContainer = styled.div`
  display: flex;
  height: 100%;
  transition: all 0.3s ease;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
`;

const CollapsedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 5px;
  gap: 15px;
`;

const ExpandedContainer = styled.div`
  display: flex;
  width: 160px;
  padding: 10px;
  flex-direction: column;
  gap: 6px;
  font-size: 14px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const Title = styled.span`
  font-weight: 600;
  color: #495057;
  font-size: 1rem;
  margin-left: 8px;
`;

const StyledRadioButton = styled(RadioButton)`
  &.p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: ${props => props.theme.colors.primary};
    background: ${props => props.theme.colors.primary};
  }

  &.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    border-color: ${props => props.theme.colors.primary};
    background: ${props => props.theme.colors.primary};
  }
`;

const StyledButton = styled(Button)`
  color: ${props => props.theme.colors.primary} !important;
  
  &:hover {
    color: ${props => props.theme.colors.primaryDark} !important;
    background-color: ${props => props.theme.colors.primaryLight} !important;
  }
  
  &:focus {
    box-shadow: 0 0 0 0.2rem ${props => props.theme.colors.primaryLight} !important;
  }
`;

const FrequencyRadio = ({ onFrequencyChange }) => {
    const intl = useIntl();
    const [selectedFrequency, setSelectedFrequency] = useState('day');
    const [expanded, setExpanded] = useState(true);

    const frequencyOptions = [
        {
            label: intl.formatMessage({ id: "title_heat_map_date_periodicity_day" }),
            value: 'day',
            icon: 'pi pi-calendar'
        },
        {
            label: intl.formatMessage({ id: "title_heat_map_date_periodicity_week" }),
            value: 'week',
            icon: 'pi pi-calendar-week'
        },
        {
            label: intl.formatMessage({ id: "title_heat_map_date_periodicity_fifteen_days" }),
            value: 'fifteen_days',
            icon: 'pi pi-calendar-times'
        },
        {
            label: intl.formatMessage({ id: "title_heat_map_date_periodicity_thirty_days" }),
            value: 'thirty_days',
            icon: 'pi pi-calendar-month'
        },
    ];

    const handleChange = (e) => {
        setSelectedFrequency(e.value);
        if (onFrequencyChange) {
            onFrequencyChange(e.value);
        }
    };

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    return (
        <SidebarContainer>
            {expanded ? (
                <ExpandedContainer>
                    <HeaderContainer>
                        <Title> <FormattedMessage id="title_heat_map_date_periodicity" /></Title>
                        <StyledButton
                            icon="pi pi-chevron-left"
                            onClick={toggleExpand}
                            className="p-button-text p-button-sm"
                        /*               tooltip={intl.formatMessage({ id: "collapse_sidebar" })}
                                      tooltipOptions={{ position: 'left' }} */
                        />
                    </HeaderContainer>

                    {frequencyOptions.map((option) => (
                        <div key={option.value} className="flex align-items-center">
                            <StyledRadioButton
                                inputId={`expanded_${option.value}`}
                                name="frequency"
                                value={option.value}
                                onChange={handleChange}
                                checked={selectedFrequency === option.value}
                            />
                            <label htmlFor={`expanded_${option.value}`} className="ml-2" style={{ marginLeft: '15px' }}>
                                {option.label}
                            </label>
                        </div>
                    ))}
                </ExpandedContainer>
            ) : (
                <CollapsedContainer>
                    <StyledButton
                        icon="pi pi-chevron-right"
                        onClick={toggleExpand}
                        className="p-button-text p-button-sm"
                    // tooltip={intl.formatMessage({ id: "expand_sidebar" })}
                    // tooltipOptions={{ position: 'right' }}
                    />

                    {frequencyOptions.map((option) => (
                        <React.Fragment key={option.value}>
                            <Tooltip target={`#collapsed_${option.value}`} content={option.label} position="right" />
                            <StyledRadioButton
                                inputId={`collapsed_${option.value}`}
                                name="frequency"
                                value={option.value}
                                onChange={handleChange}
                                checked={selectedFrequency === option.value}
                                id={`collapsed_${option.value}`}
                            />
                        </React.Fragment>
                    ))}
                </CollapsedContainer>
            )}
        </SidebarContainer>
    );
};

export default FrequencyRadio;