import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from "react-router-dom";

import Signin from "../pages/Signin";
import ForgotPassword from "../pages/ForgotPassword";
import Intercept from "../pages/Intercept";

import {
    LoadingOverlay,
    LoadingSpinnerWrapper,
} from '../pages/Signin/styles'

import { initKeycloak, keycloak } from '../keycloak';

// Component for routes that require Keycloak authentication
const AuthRoute = ({ element: Component }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const checkAuth = async () => {
            try {
                await new Promise(resolve => setTimeout(resolve, 1500));

                if (!keycloak) {
                    setError(true);
                    setLoading(false);
                    return;
                }

                if (!keycloak.authenticated) {
                    localStorage.setItem('isKcAda', 'true');

                    try {
                        await keycloak.login();
                    } catch (e) {
                        setError(true);
                    }
                }
            } catch (err) {
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        checkAuth();

        if (keycloak) {
            keycloak.onAuthSuccess = () => {
                setLoading(false);
                setError(false);
            };

            keycloak.onAuthError = () => {
                setLoading(false);
                setError(true);
            };

            return () => {
                keycloak.onAuthSuccess = null;
                keycloak.onAuthError = null;
            };
        }
    }, []);

    if (loading) {
        return <LoadingOverlay>
            <LoadingSpinnerWrapper />
        </LoadingOverlay>
    }

    if (error) {
        return <Navigate to="/" />;
    }

    return <Component />;
};

const AuthRoutes = () => {
    const [loading, setLoading] = useState(true);
    const [kcError, setKcError] = useState(false);

    useEffect(() => {
        const initializeKeycloak = async () => {
            const isInterceptRoute = window.location.pathname.includes('/intercept');
            const useKeycloak = localStorage.getItem('isKcAda') === 'true' || isInterceptRoute;

            if (useKeycloak) {
                if (isInterceptRoute) {
                    localStorage.setItem('isKcAda', 'true');

                    const pathParts = window.location.pathname.split('/');
                    const clientId = pathParts[pathParts.length - 1];
                    if (clientId && clientId.length > 0) {
                        localStorage.setItem('last_client', clientId);
                    }
                }

                try {
                    const kc = await initKeycloak();
                    if (!kc && isInterceptRoute) {
                        setKcError(true);
                    }
                } catch (error) {
                    if (isInterceptRoute) {
                        setKcError(true);
                    }
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        initializeKeycloak();
    }, []);

    if (loading) {
        return <LoadingOverlay>
            <LoadingSpinnerWrapper />
        </LoadingOverlay>
    }

    if (kcError && window.location.pathname.includes('/intercept')) {
        return <Navigate to="/" />;
    }

    return (
        <Routes>
            <Route path="/" element={<Signin />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/intercept/:clientId" element={<AuthRoute element={Intercept} />} /> 
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
};

export default AuthRoutes;
