import Keycloak from 'keycloak-js';
import { URL_BASE_KEYCLOAK, CLIENT_ID } from './config/config';
import { fetchDataFromAPIRealms } from './realms/realms';
import { cleanKeycloakCookies } from './utils/cookies';

let realms = [];
let defaultRealm = 'default';

const initializeRealms = async () => {
  const listLocalStorage = localStorage.getItem('realms-list-stt4');
  const last_client = localStorage.getItem('last_client');

  if (!listLocalStorage || !last_client) {
    try {
      const data = await fetchDataFromAPIRealms();

      if (typeof data === 'string') {
        realms = JSON.parse(data);
      } else if (Array.isArray(data)) {
        realms = data;
      } else {
        realms = []; 
      }

      localStorage.setItem('realms-list-stt4', JSON.stringify(realms));
    } catch (error) {
      realms = []; 
    }
  } else {
    try {
      realms = JSON.parse(listLocalStorage);
      if (!Array.isArray(realms)) {
        realms = []; 
      }
    } catch (error) {
      realms = [];
    }
  }

  if (last_client && last_client.length > 0) {
    const lastClientResult = realms.find(item => item.client === last_client);
    if (lastClientResult) {
      defaultRealm = lastClientResult.realm;
    }
  }

  const path = window.location.pathname;
  const partPath = path.split('/');
  const client = String(partPath[partPath.length - 1]);

  const result = realms.find(item => item.client === client);
  const realm = result ? result.realm : defaultRealm;

  return {
    url: URL_BASE_KEYCLOAK,
    realm: realm,
    clientId: CLIENT_ID,
  };
};

let keycloak = null;

const shouldInitializeKeycloak = () => {
  const isInterceptRoute = window.location.pathname.includes('/intercept');
  const isKcAdaTrue = localStorage.getItem('isKcAda') === 'true';
  
  return isInterceptRoute || isKcAdaTrue;
};

const initKeycloak = async () => {
  if (!shouldInitializeKeycloak()) {
    return null;
  }
  
  if (keycloak) {
    return Promise.resolve(keycloak);
  }

  try {
    const keycloakConfig = await initializeRealms();
    keycloak = new Keycloak(keycloakConfig);

    await keycloak.init({ 
      onLoad: 'check-sso',
    });
    
    const isInterceptRoute = window.location.pathname.includes('/intercept');
    if (isInterceptRoute && !keycloak.authenticated) {
      cleanKeycloakCookies();
    }
    
    return keycloak;
  } catch (error) {
    keycloak = null;
    return null;
  }
};

const safeKeycloak = new Proxy({}, {
  get: (target, prop) => {
    if (keycloak === null) {
      return undefined;
    }
    return keycloak[prop];
  }
});

export { initKeycloak, safeKeycloak as keycloak };