/**
 * Utility functions for cookie management
 */

/**
 * Cleans all Keycloak-related cookies by setting their expiration date to the past
 * This helps prevent issues with stale Keycloak authentication data
 */
export const cleanKeycloakCookies = () => {
  const cookies = document.cookie.split(';');
  const keycloakPrefixes = ['KEYCLOAK_', 'KC_', 'kc_', 'AUTH_SESSION_'];
  const specificCookies = ['JSESSIONID', 'KEYCLOAK_IDENTITY', 'KEYCLOAK_SESSION'];
  
  // Extract base domain and its variants
  const domain = window.location.hostname;
  let domains = [domain];
  
  // Add variant without 'www.' if it exists
  if (domain.startsWith('www.')) {
    domains.push(domain.substring(4));
  } else {
    domains.push('www.' + domain);
  }
  
  const domainParts = domain.split('.');
  if (domainParts.length > 2) {
    const rootDomain = domainParts.slice(domainParts.length - 2).join('.');
    domains.push(rootDomain);
    domains.push('www.' + rootDomain);
  }
  
  const paths = ['/', '/keycloak', '/auth', ''];
  
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    const name = cookie.split('=')[0];
    
    const isKeycloakCookie = keycloakPrefixes.some(prefix => name.indexOf(prefix) === 0) || 
                             specificCookies.includes(name);
    
    if (isKeycloakCookie) {
      for (const d of domains) {
        for (const p of paths) {
          document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${p};`;
          document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${p}; domain=${d};`;
        }
      }
    }
  }
}; 