import styled, { css, keyframes } from "styled-components";
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { ProgressSpinner } from "primereact/progressspinner";
import { Calendar } from 'primereact/calendar';
import { Slider } from 'primereact/slider';

export const Container = styled.div`
  display: flex;
  height: 93vh;
  position: relative;
  overflow: hidden;
`;



export const SidebarContainer = styled.div`
  width: ${props => (props.$collapsed ? '50px' : '21%')};
  transition: width 0.3s;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
`;

export const SidebarToggleButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000; 
  background: #333;
  color: white;
  border: none;
  padding: 2px 5px;
  cursor: pointer;
  font-size: 10px;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: ${props => (props.$collapsed ? '50px' : '21%')};
  transition: margin-left 0.3s;
  width: auto;
  max-width: ${props => (props.$collapsed ? 'calc(100% - 50px)' : 'calc(100% - 21%)')};
  overflow: hidden;
`;

export const MapContainer = styled.div`
  height: ${props => props.$collapsed ? '65%' : '60%'};
  background-color: #e0e0e0;
  margin: 10px;
  transition: all 0.3s;
  border-radius: 8px;
  overflow: hidden;
`;

export const ChartContainer = styled.div`
  flex: 1;
  background-color: #f0f0f0;
  margin: 10px;
 
`;



export const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin: 5px 10px;
`;

export const CustomSidebar = styled(Sidebar)`
  .p-sidebar-close {
    display: none;
  }
`;

export const CustomSpinner = styled(ProgressSpinner)`
  stroke: green;
`;

export const ApplyButton = styled(Button)`
  background-color: ${props => props.theme.colors.primary};
  color: #ffffff;
  border: none; 

  &:hover {
      background-color: ${props => props.theme.colors.primary};
  }

  &:active {
      background-color: ${props => props.theme.colors.primary};
  }
`;


export const StyledCalendar = styled(Calendar)`
.p-datepicker-trigger {
  background-color: ${props => props.theme.colors.primary};
  color: #ffffff; 
  border: none; 
  transition: background-color 0.3s ease; 

  &:hover {
    background-color: #6366F1;
  }

  &:active {
    background-color: ${props => props.theme.colors.primary};
  }
}
`;


export const StyledButtonNavigation = styled(Button)`
  height: 30px;
  background-color: ${props => props.theme.colors.primary};
  color: #ffffff;
  border: none; 
  transition: background-color 0.3s ease; 

  &:hover {
    background-color: #6366F1;
  }

  &:active {
    background-color: ${props => props.theme.colors.primary};
  }

  &:disabled {
    background-color: #6366F1; 
    cursor: not-allowed; 
  }
`;


export const StyledSlider = styled(Slider)`
  width: 90%; 
  margin: 0 10px; 
  position: relative;

  .p-slider-range {
    background-color: ${props => props.theme.colors.primary};
    position: relative;
    
    ${props => props.$showBar && `
      /* Barra vertical na parte inferior (somente quando devices > 0) */
      &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 19.2rem;
        background: ${props.theme.colors.primary};
        margin-top: 2px;
        opacity: 0.2;
      }
    `}
    
    ${props => props.date && props.date.length > 0 && `
      /* Balão com a data na parte superior */
      &::before {
        content: '${props.date}';
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        color: #000;
        font-size: 12px;
        padding: 2px 5px;
        margin-bottom: 2px;
        font-weight: bold;
        background: #fff;
        border-radius: 3px;
        white-space: nowrap;
        z-index: 1;
      }
    `}
  }

  .p-slider-handle {
    background-color: ${props => props.theme.colors.primary};
    border: 2px solid #ffffff;
  }

  &.p-slider.p-disabled {
    opacity: 1; 
    .p-slider-handle {
      background-color: ${props => props.theme.colors.primary}; 
    }
  }
`;