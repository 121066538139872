import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
} from 'chart.js';
import { useIntl } from 'react-intl';
import { Tooltip as PrimeReactTooltip } from "primereact/tooltip";
import reset from "../../../assets/img/ICONE.png";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  zoomPlugin
);

const formatDate = (date) => {
  if (!date) return '';
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, '0');
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const year = d.getFullYear();
  return `${day}/${month}/${year}`;
};

const PressureChart = ({ devices, expanded, startDate, endDate, currentStartDate, currentEndDate, referenceValue, style, onLineClick, verticalLineDate }) => {

  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({ datasets: [] });
  const [flag, setFlag] = useState(false);
  const lastZoomLevelRef = useRef(null);
  const [appliedDates, setAppliedDates] = useState({ start: startDate, end: endDate });
  const [selectedDatasetIndex, setSelectedDatasetIndex] = useState(null);
  const intl = useIntl();

  const { adjustedStartDate, adjustedEndDate } = useMemo(() => {
    if (!appliedDates.start || !appliedDates.end) {
      return {
        adjustedStartDate: new Date(),
        adjustedEndDate: new Date()
      };
    }

    const startDateObj = appliedDates.start instanceof Date ? appliedDates.start : new Date(appliedDates.start);
    const endDateObj = appliedDates.end instanceof Date ? appliedDates.end : new Date(appliedDates.end);

    const start = new Date(startDateObj);
    start.setHours(0, 0, 0, 0);

    const end = new Date(endDateObj);
    end.setHours(23, 59, 59, 999);

    return { adjustedStartDate: start, adjustedEndDate: end };
  }, [appliedDates]);

  useEffect(() => {
    if (devices && devices.length > 0) {
      setAppliedDates({ start: startDate, end: endDate });
    }
  }, [devices]);

  const verticalLinePositions = useMemo(() => {
    if (!verticalLineDate) return [];

    if (Array.isArray(verticalLineDate)) {
      return verticalLineDate.map(date => {
        if (!date) return null;
        return date instanceof Date ? date.getTime() : new Date(date).getTime();
      }).filter(Boolean);
    }

    const date = verticalLineDate;
    return [date instanceof Date ? date.getTime() : new Date(date).getTime()];
  }, [verticalLineDate]);


  const verticalLinePlugin = {
    id: 'verticalLine',
    beforeDraw: (chart) => {
      if (verticalLinePositions.length === 0) return;

      const ctx = chart.ctx;
      const xAxis = chart.scales.x;
      const yAxis = chart.scales.y;

      const lineColors = ['rgba(0, 128, 0, 0.7)', 'rgba(255, 0, 0, 0.7)'];
      const lineLabels = ['Início', 'Fim'];

      verticalLinePositions.forEach((position, index) => {
        if (!position) return;

        const xPos = xAxis.getPixelForValue(position);

        if (xPos >= xAxis.left && xPos <= xAxis.right) {
          ctx.save();

          // Desenha a linha
          ctx.beginPath();
          ctx.moveTo(xPos, yAxis.top);
          ctx.lineTo(xPos, yAxis.bottom);
          ctx.lineWidth = 2;
          ctx.strokeStyle = lineColors[index] || 'rgba(0, 0, 255, 0.7)';
          ctx.setLineDash([5, 3]);
          ctx.stroke();

          // Adiciona texto
          ctx.font = 'bold 12px Arial';
          ctx.fillStyle = lineColors[index] || 'rgba(0, 0, 255, 0.9)';
          ctx.textAlign = 'center';
          ctx.fillText(
            `${lineLabels[index] || ''} ${formatDate(new Date(position))}`,
            xPos,
            yAxis.top - 10
          );

          ctx.restore();
        }
      });
    }
  };

  useEffect(() => {
    ChartJS.register({
      ...verticalLinePlugin,
      verticalLinePositions
    });

    if (chartRef.current) {
      chartRef.current.update();
    }

    return () => {
      ChartJS.unregister(verticalLinePlugin);
    };
  }, [verticalLinePositions]);

  const generateDatasets = useCallback(() => {
    if (!devices || !Array.isArray(devices) || devices.length === 0) {
      return [];
    }

    const deviceMap = {};

    devices.forEach(device => {
      if (!device) return;

      const deviceId = device.deviceId;
      if (!deviceId) return;

      const serialNumber = device.serialNumber || '';

      if (!deviceMap[deviceId]) {
        deviceMap[deviceId] = {
          label: `Device ${deviceId}`,
          data: [],
          serialNumber: serialNumber
        };
      }

      const pressureValue = device[referenceValue] || 0;
      let date;

      try {
        date = device.timestamp
          ? new Date(device.timestamp * 1000)
          : new Date(device.datetime + 'Z');
      } catch (error) {
        console.error("Error al crear fecha:", error);
        return;
      }

      const timestamp = date.getTime();
      const isInCurrentRange = currentStartDate && currentEndDate ?
        timestamp >= currentStartDate.getTime() && timestamp <= currentEndDate.getTime() :
        false;

      const formattedDate = formatDateUTC(date);

      deviceMap[deviceId].data.push({
        x: timestamp,
        y: pressureValue,
        formattedDate: formattedDate,
        isInCurrentRange: isInCurrentRange,
        serialNumber: serialNumber,
        deviceId: deviceId
      });
    });

    return Object.keys(deviceMap).map((deviceId, index) => {
      const deviceData = deviceMap[deviceId];
      const color = `hsl(${(index * 360) / Object.keys(deviceMap).length}, 70%, 50%)`;

      deviceData.data.sort((a, b) => a.x - b.x);

      return {
        label: deviceData.label,
        data: deviceData.data,
        borderColor: color,
        backgroundColor: color,
        borderWidth: selectedDatasetIndex === index ? 4 : 2,
        pointRadius: (context) => {
          const point = context.raw;
          return point && point.isInCurrentRange ? 5 : 3;
        },
        pointBackgroundColor: (context) => {
          const point = context.raw;
          if (point && point.isInCurrentRange) {
            return color;
          }
          return 'rgba(255, 255, 255, 0.7)';
        },
        pointBorderColor: color,
        pointBorderWidth: selectedDatasetIndex === index ? 3 : 1,
        fill: false,
        tension: 0.1
      };
    });
  }, [devices, referenceValue, currentStartDate, currentEndDate, selectedDatasetIndex]);

  function formatDateUTC(date) {
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  }

  useEffect(() => {
    const datasetArray = generateDatasets();
    setChartData({ datasets: datasetArray });
  }, [generateDatasets]);

  const handleZoomComplete = (chart) => {
    if (chart) {
      const currentZoomLevelMin = chart.chart.scales["x"].min;
      const currentZoomLevelMax = chart.chart.scales["x"].max;
      lastZoomLevelRef.current = {
        min: currentZoomLevelMin,
        max: currentZoomLevelMax,
      };
      setFlag(true);
    }
  };

  const resetZoomBtn = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.resetZoom();
      setFlag(false);
    }
  };

  const handleLineClick = (event, elements) => {
    if (elements && elements.length > 0) {
      const element = elements[0];
      const clickedDatasetIndex = element.datasetIndex;

      const newSelectedIndex = selectedDatasetIndex === clickedDatasetIndex ? null : clickedDatasetIndex;
      setSelectedDatasetIndex(newSelectedIndex);

      if (onLineClick) {
        if (newSelectedIndex !== null) {
          const dataIndex = element.index;
          const clickedData = chartData.datasets[clickedDatasetIndex].data[dataIndex];
          onLineClick(clickedData);
        } else {
          onLineClick(null);
        }
      }
    } else {

      setSelectedDatasetIndex(null);
      if (onLineClick) {
        onLineClick(null);
      }
    }
  };

  const chartOptions = useMemo(() => ({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: 'Pressão  dos Dispositivos ao Longo do Tempo',
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: false,
          },
          pinch: {
            enabled: true,
          },
          mode: "xy",
          drag: {
            enabled: true,
          },
          onZoomComplete: (chart) => {
            handleZoomComplete(chart);
          },
        },
        pan: {
          enabled: true,
          mode: 'xy',
          modifierKey: 'ctrl',
        },
        drag: {
          backgroundColor: "rgba(110, 255, 148, 0.4)",
        },
      },
      tooltip: {
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        titleColor: '#333',
        bodyColor: '#333',
        borderColor: '#ddd',
        borderWidth: 1,
        padding: 10,
        displayColors: true,
        callbacks: {
          title: function (context) {
            if (!context || !context[0] || !context[0].raw) return '';
            const dataPoint = context[0].raw;
            return dataPoint.formattedDate || '';
          },
          label: function (context) {
            if (!context || !context.raw) return '';
            const dataPoint = context.raw;
            const dataset = context.dataset;

            const lines = [];

            if (dataPoint.serialNumber || dataset.serialNumber) {
              lines.push(`${intl.formatMessage({ id: 'serie_number' })}: ${dataPoint.serialNumber || dataset.serialNumber}`);
            }

            lines.push(`${intl.formatMessage({ id: 'pressure_mca' })}: ${context.formattedValue}`);

            return lines;
          }
        }
      },
      verticalLine: {
        position: verticalLinePositions
      }
    },
    scales: {
      x: {
        type: 'linear',
        min: flag ? lastZoomLevelRef.current.min : adjustedStartDate.getTime(),
        max: flag ? lastZoomLevelRef.current.max : adjustedEndDate.getTime(),
        title: {
          display: false,
          text: 'Data',
        },
        position: "bottom",
        grid: {
          drawBorder: false,
          drawTicks: false,
          color: "#eef0fa",
          zeroLineColor: "rgba(90, 113, 208, 0)",
        },
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
          color: "#878f87",
          padding: 5,
          callback: function (value) {
            return formatDate(new Date(value));
          }
        },
        afterBuildTicks: (scale) => {
          scale.min = scale.min - (10 * (scale.max - scale.min) / scale.width);
          scale.max = scale.max;
        },
      },
      y: {
        title: {
          display: true,
          text: intl.formatMessage({ id: 'pressure_mca' }),
          color: "#000000",
          font: {
            size: 15,
            weight: '500',
          },
          rotation: 270,
        },
        beginAtZero: false,
      },
    },
    onClick: handleLineClick,
    hover: {
      mode: 'nearest',
      intersect: true
    },
    interaction: {
      mode: 'nearest',
      intersect: false
    }
  }), [adjustedStartDate, adjustedEndDate, intl, flag, chartData, onLineClick, verticalLinePositions, verticalLineDate]);

  if (!devices || !Array.isArray(devices) || devices.length === 0) {
    return (
      <div style={{
        ...(style || {}),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f8f9fa',
        borderRadius: '4px',
        height: '100%',
        width: '100%'
      }}>
        <div style={{
          textAlign: 'center',
          padding: '20px',
          color: '#6c757d'
        }}>
          <i className="pi pi-info-circle" style={{ fontSize: '2rem', marginBottom: '10px' }}></i>
          <p>{intl.formatMessage({ id: 'title_heat_map_no_data_for_period' })}</p>
          {currentStartDate && currentEndDate && (
            <div style={{
              marginTop: '10px',
              fontSize: '0.85rem'
            }}>
              <span>{formatDate(currentStartDate)} - {formatDate(currentEndDate)}</span>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div style={{ width: '100%', height: '100%', padding: '10px' }}>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 20px',
        marginBottom: '5px'
      }}>
        <div style={{
          color: '#0066cc',
          fontWeight: 'bold',
          fontSize: '0.85rem'
        }}>
        </div>
        <div>
          <PrimeReactTooltip target=".custom-target-btn-reset" />
          <button
            className="custom-target-btn-reset"
            onClick={() => resetZoomBtn()}
            data-pr-tooltip={intl.formatMessage({ id: "reset_zoom" })}
            data-pr-position="left"
            style={{
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '26px',
              height: '0px',
              backgroundColor: 'white',
              border: '1px solid #ddd',
              borderRadius: '50%',
              cursor: 'pointer',
              padding: '2px'
            }}
          >
            <img
              src={reset}
              width={20}
              alt="Reset"
              style={{ verticalAlign: 'middle' }}
            />
          </button>
        </div>
      </div>
      <Line
        ref={chartRef}
        data={chartData}
        options={chartOptions}
        style={{
          height: '100%',
          width: '100%',
          maxHeight: '38vh'
        }}
      />
    </div>
  );
};

export default PressureChart;